.PageNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 4rem;
  width: 100%;
}
.PageNotFound span {
  font-size: 200%;
}
