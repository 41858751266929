/* Color variables */
:root {
  --white: #ffffffff;
  --light-grey: #f0eeea;
  --grey: #bab8b5;
  --dark-grey: #777472;
  --black: #000000;
  --header-black: #000000;
  --orange: #ffcc00;
  --green: #55d099;
  --red: #ff3b30;
  --red-dark: hsl(3, 100%, 39%);
  --light-red: #fceff0;
  --blue: #336ce7;
  --footer-blue: #231756;
  --background: #eaeaea;
  --primary: #336ce7;
  --primary-desaturated: hsl(221, 39%, 55%);
  --primary-light: hsl(221, 79%, 75%);
  --primary-dark: hsl(221, 79%, 35%);
  --accent: #ffffffff;
}

/* Milligram.css - but with default purple changed to Dolla green */
*,
*:after,
*:before {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
  font-size: 62.5%;
}
body {
  color: #606c76;
  font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1.6em;
  font-weight: 300;
  letter-spacing: 0.01em;
  line-height: 1.6;
  padding: 0;
  margin: 0;
}
blockquote {
  border-left: 0.3rem solid #d1d1d1;
  margin-left: 0;
  margin-right: 0;
  padding: 1rem 1.5rem;
}
blockquote *:last-child {
  margin-bottom: 0;
}
.button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: var(--primary);
  border: 0.1rem solid var(--primary);
  border-radius: 0.4rem;
  color: var(--white);
  cursor: pointer;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 600;
  height: 3.8rem;
  letter-spacing: 0.1rem;
  line-height: 3.8rem;
  min-height: 50px;
  padding: 0 3rem;
  margin-top: 1rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
}
.button:focus,
.button:hover,
button:focus,
button:hover,
input[type="button"]:focus,
input[type="button"]:hover,
input[type="reset"]:focus,
input[type="reset"]:hover,
input[type="submit"]:focus,
input[type="submit"]:hover {
  background-color: var(--primary-dark);
  outline: 0;
}
.button[disabled],
button[disabled],
input[type="button"][disabled],
input[type="reset"][disabled],
input[type="submit"][disabled] {
  cursor: default;
  opacity: 0.5;
}
.button[disabled]:focus,
.button[disabled]:hover,
button[disabled]:focus,
button[disabled]:hover,
input[type="button"][disabled]:focus,
input[type="button"][disabled]:hover,
input[type="reset"][disabled]:focus,
input[type="reset"][disabled]:hover,
input[type="submit"][disabled]:focus,
input[type="submit"][disabled]:hover {
}
.button.button-outline,
button.button-outline,
input[type="button"].button-outline,
input[type="reset"].button-outline,
input[type="submit"].button-outline {
  background-color: transparent;
  color: var(--primary);
}
.button.button-outline:focus,
.button.button-outline:hover,
button.button-outline:focus,
button.button-outline:hover,
input[type="button"].button-outline:focus,
input[type="button"].button-outline:hover,
input[type="reset"].button-outline:focus,
input[type="reset"].button-outline:hover,
input[type="submit"].button-outline:focus,
input[type="submit"].button-outline:hover {
  background-color: transparent;
  border-color: #606c76;
  color: #606c76;
}
.button.button-outline[disabled]:focus,
.button.button-outline[disabled]:hover,
button.button-outline[disabled]:focus,
button.button-outline[disabled]:hover,
input[type="button"].button-outline[disabled]:focus,
input[type="button"].button-outline[disabled]:hover,
input[type="reset"].button-outline[disabled]:focus,
input[type="reset"].button-outline[disabled]:hover,
input[type="submit"].button-outline[disabled]:focus,
input[type="submit"].button-outline[disabled]:hover {
  border-color: inherit;
  color: var(--primary);
}
.button.button-clear,
button.button-clear,
input[type="button"].button-clear,
input[type="reset"].button-clear,
input[type="submit"].button-clear {
  background-color: transparent;
  border-color: transparent;
  color: var(--primary);
}
.button.button-clear:focus,
.button.button-clear:hover,
button.button-clear:focus,
button.button-clear:hover,
input[type="button"].button-clear:focus,
input[type="button"].button-clear:hover,
input[type="reset"].button-clear:focus,
input[type="reset"].button-clear:hover,
input[type="submit"].button-clear:focus,
input[type="submit"].button-clear:hover {
  background-color: transparent;
  border-color: transparent;
  color: #606c76;
}
.button.button-clear[disabled]:focus,
.button.button-clear[disabled]:hover,
button.button-clear[disabled]:focus,
button.button-clear[disabled]:hover,
input[type="button"].button-clear[disabled]:focus,
input[type="button"].button-clear[disabled]:hover,
input[type="reset"].button-clear[disabled]:focus,
input[type="reset"].button-clear[disabled]:hover,
input[type="submit"].button-clear[disabled]:focus,
input[type="submit"].button-clear[disabled]:hover {
  color: var(--primary);
}
code {
  background: #f4f5f6;
  border-radius: 0.4rem;
  font-size: 86%;
  margin: 0 0.2rem;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}
pre {
  background: #f4f5f6;
  border-left: 0.3rem solid var(--primary);
  overflow-y: hidden;
}
pre > code {
  border-radius: 0;
  display: block;
  padding: 1rem 1.5rem;
  white-space: pre;
}
hr {
  border: 0;
  border-top: 0.1rem solid #f4f5f6;
  margin: 3rem 0;
}
input[type="email"],
input[type="phone"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
input[type="date"],
textarea,
select {
  appearance: none;
  background-color: #ffffff;
  border: 0.1rem solid var(--grey);
  border-radius: 0.4rem;
  box-shadow: none;
  box-sizing: inherit;
  height: 3.8rem;
  padding: 0.6rem 1rem;
  width: 100%;
  font-size: 100%;
}
input[type="email"]:focus,
input[type="phone"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="url"]:focus,
input[type="date"]:focus,
textarea:focus,
select:focus {
  border-color: var(--primary-light);
  outline: 0;
}
select {
  background: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2214%22%20viewBox%3D%220%200%2029%2014%22%20width%3D%2229%22%3E%3Cpath%20fill%3D%22%23d1d1d1%22%20d%3D%22M9.37727%203.625l5.08154%206.93523L19.54036%203.625%22%2F%3E%3C%2Fsvg%3E")
    center right no-repeat;
  padding-right: 3rem;
}
select:focus {
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2214%22%20viewBox%3D%220%200%2029%2014%22%20width%3D%2229%22%3E%3Cpath%20fill%3D%22%23d1d1d1%22%20d%3D%22M9.37727%203.625l5.08154%206.93523L19.54036%203.625%22%2F%3E%3C%2Fsvg%3E");
}
textarea {
  min-height: 6.5rem;
}
label,
legend {
  text-align: left;
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
fieldset {
  border-width: 0;
  padding: 0;
}
input[type="checkbox"],
input[type="radio"] {
  display: inline;
}
.label-inline {
  display: inline-block;
  font-weight: normal;
  margin-left: 0.5rem;
}
.container {
  margin: 0 auto;
  max-width: 112rem;
  padding: 0 2rem;
  position: relative;
  width: 100%;
}
.row {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
}
.row.row-no-padding {
  padding: 0;
}
.row.row-no-padding > .column {
  padding: 0;
}
.row.row-wrap {
  flex-wrap: wrap;
}
.row.row-top {
  align-items: flex-start;
}
.row.row-bottom {
  align-items: flex-end;
}
.row.row-center {
  align-items: center;
}
.row.row-stretch {
  align-items: stretch;
}
.row.row-baseline {
  align-items: baseline;
}
.row .column {
  display: block;
  flex: 1 1 auto;
  margin-left: 0;
  max-width: 100%;
  width: 100%;
}
.row .column.column-offset-10 {
  margin-left: 10%;
}
.row .column.column-offset-20 {
  margin-left: 20%;
}
.row .column.column-offset-25 {
  margin-left: 25%;
}
.row .column.column-offset-33,
.row .column.column-offset-34 {
  margin-left: 33.3333%;
}
.row .column.column-offset-50 {
  margin-left: 50%;
}
.row .column.column-offset-66,
.row .column.column-offset-67 {
  margin-left: 66.6666%;
}
.row .column.column-offset-75 {
  margin-left: 75%;
}
.row .column.column-offset-80 {
  margin-left: 80%;
}
.row .column.column-offset-90 {
  margin-left: 90%;
}
.row .column.column-10 {
  flex: 0 0 10%;
  max-width: 10%;
}
.row .column.column-20 {
  flex: 0 0 20%;
  max-width: 20%;
}
.row .column.column-25 {
  flex: 0 0 25%;
  max-width: 25%;
}
.row .column.column-33,
.row .column.column-34 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}
.row .column.column-40 {
  flex: 0 0 40%;
  max-width: 40%;
}
.row .column.column-50 {
  flex: 0 0 50%;
  max-width: 50%;
}
.row .column.column-60 {
  flex: 0 0 60%;
  max-width: 60%;
}
.row .column.column-66,
.row .column.column-67 {
  flex: 0 0 66.6666%;
  max-width: 66.6666%;
}
.row .column.column-75 {
  flex: 0 0 75%;
  max-width: 75%;
}
.row .column.column-80 {
  flex: 0 0 80%;
  max-width: 80%;
}
.row .column.column-90 {
  flex: 0 0 90%;
  max-width: 90%;
}
.row .column .column-top {
  align-self: flex-start;
}
.row .column .column-bottom {
  align-self: flex-end;
}
.row .column .column-center {
  align-self: center;
}
@media (min-width: 40rem) {
  .row {
    flex-direction: row;
    margin-left: -1rem;
    width: calc(100% + 2rem);
  }
  .row .column {
    margin-bottom: inherit;
    padding: 0 1rem;
  }
}
a {
  color: var(--primary-desaturated);
  text-decoration: none;
}
a:focus,
a:hover {
  color: var(--primary-dark);
  cursor: pointer;
}
dl,
ol,
ul {
  list-style: none;
  margin-top: 0;
  padding-left: 0;
}
dl dl,
dl ol,
dl ul,
ol dl,
ol ol,
ol ul,
ul dl,
ul ol,
ul ul {
  font-size: 90%;
  margin: 1.5rem 0 1.5rem 3rem;
}
ol {
  list-style: decimal inside;
}
ul {
  list-style: circle inside;
}
.button,
button,
dd,
dt,
li {
  margin-bottom: 1rem;
}
fieldset,
input,
select,
textarea {
  margin-bottom: 1.5rem;
}
blockquote,
dl,
figure,
form,
ol,
p,
pre,
table,
ul {
  width: 100%;
  margin-bottom: 2.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
table {
  border-spacing: 0;
  width: 100%;
}
td,
th {
  border-bottom: 0.1rem solid #e1e1e1;
  padding: 1.2rem 1.5rem;
  text-align: left;
}
td:first-child,
th:first-child {
  padding-left: 0;
}
td:last-child,
th:last-child {
  padding-right: 0;
}
b,
strong {
  font-weight: bold;
}
p {
  margin-top: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  letter-spacing: -0.1rem;
  margin-bottom: 2rem;
  margin-top: 0;
}
h1 {
  font-size: 4.6rem;
  line-height: 1.2;
}
h2 {
  font-size: 3.6rem;
  line-height: 1.25;
}
h3 {
  font-size: 2.8rem;
  line-height: 1.3;
}
h4 {
  font-size: 2.2rem;
  letter-spacing: -0.08rem;
  line-height: 1.35;
}
h5 {
  font-size: 1.8rem;
  letter-spacing: -0.05rem;
  line-height: 1.5;
}
h6 {
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.4;
}
img {
  max-width: 100%;
}
svg {
  vertical-align: text-top;
}
.clearfix:after {
  clear: both;
  content: " ";
  display: table;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
