.WidgetPage {
  width: 100%;
}
.widget-demo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border: 2px dashed var(--primary);
  border-radius: 1rem;
  margin-top: 1rem;
    margin-bottom: 1rem;
}

code {
    overflow: scroll;
    flex:none
}

pre {
  padding-top: 20px;
  padding-bottom: 20px;
}