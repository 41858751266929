.Wrapper {
  /* background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' fill='%23f0f0f0' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E"); */
  min-height: 100vh;
  /* max-width: 100vw; */
  display: flex;
  flex-direction: column;
  overflow-x: hidden; /* TEMP FIX - not sure why there is extra horizontal space on mobile? */
}

/* Header */
.Wrapper > header {
  background-color: var(--white);
  color: var(--white);
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Wrapper > header > nav {
  width: 100%;
  max-width: 80rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 3rem;
  margin-right: 3rem;
}
.Wrapper > header > nav .logo {
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  min-height: 4rem;
  margin-top: 0.75rem;
  border-radius: 3.5rem;
}
.Wrapper > header > nav .links {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 600;
}
.Wrapper > header > nav .roundIcon {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px rgba(255, 255, 255, 0.8) solid;
  border-radius: 100%;
  padding: 0.1rem;
  background: transparent;
  color: var(--white);
  margin-bottom: 0;
}
.Wrapper > header > nav .roundIcon:hover {
  color: var(--primary);
}
.Wrapper > header > nav .WrapperTippy {
  background-color: var(--white);
  border: 1px solid var(--grey);
}
.Wrapper > header > nav .WrapperTippy .tippy-arrow {
  color: var(--white);
}
.Wrapper > header > nav .WrapperTippy .tippy-content {
  padding: 1rem 0;
}
.Wrapper > header > nav .WrapperTippy .tippy-content div {
  display: flex;
  flex-direction: column;
}
.Wrapper > header > nav .WrapperTippy .tippyLink {
  padding: 0.5rem 1rem;
  width: 100%;
  color: var(--black);
  cursor: pointer;
}
.Wrapper > header > nav .WrapperTippy .tippyLink:hover {
  background-color: var(--light-grey);
  border-radius: 3px;
}

/* Content */
.Wrapper > section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.Wrapper > section > .content {
  max-width: 80rem;
  /* width: 60rem; */
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 75vh;
}
.Wrapper > section > .content.wide {
  max-width: 100%;
  width: 100%;
}

/* Footer */
.Wrapper > footer {
  flex-grow: 1;
  background-color: var(--light-grey);
  font-size: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  padding: 1.5rem;
  width: 100%;
}
.Wrapper > footer > .footerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  vertical-align: middle;
}
.Wrapper > footer > .footerWrapper > .store {
  width: 100%;
  max-width: 100rem;
}
.Wrapper > footer > .footerWrapper > .store {
  flex-grow: 1;
  font-size: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--grey);
  padding: 1.5rem;
  width: 100%;
}
.Wrapper > footer > .footerWrapper > .store > p {
  font-size: 1.4rem;
  font-weight: 400;
}

.Wrapper > footer > .footerWrapper > .store > .badges {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Wrapper > footer > .footerWrapper > .store > .badges > a > img {
  max-height: 4rem;
  height: 4rem;
  width: auto;
}
.Wrapper > footer > .footerWrapper > .store > p {
  margin-bottom: 0;
}
.Wrapper > footer > .footerWrapper > .store > p > a {
  color: var(--primary);
}
@media only screen and (max-width: 600px) {
  .Wrapper > footer > .footerWrapper > .store {
    background-color: var(--primary);
    font-size: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: var(--grey);
    padding: 1.5rem;
    width: 100%;
  }
  .Wrapper > footer > .footerWrapper > .store > p {
    text-align: center;
    margin-bottom: 1.2rem;
    font-size: 1.2rem;
  }
  .Wrapper > footer > .footerWrapper > .store > .badges {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .Wrapper > footer > .footerWrapper > .store > .badges > a > img {
    width: 100%;
    height: auto;
  }
}
